import * as Turbo from "@hotwired/turbo";
Turbo.start();

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import { Application } from "stimulus";
const application = Application.start();

import { registerControllers } from "stimulus-vite-helpers";

const controllers = import.meta.globEager(
  "~/javascript/controllers/**/*_controller.js"
);
registerControllers(application, controllers);

import "chartkick/chart.js";
