import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'checkboxAll',
    'checkbox',
    'values',
    'button',
    'checkedCount',
  ];

  initialize() {
    this.toggleAll = this.toggleAll.bind(this);
    this.toggleOne = this.toggleOne.bind(this);
  }

  connect() {
    if (!this.hasCheckboxAllTarget) return;

    this.checkboxAllTarget.addEventListener('change', this.toggleAll);
    this.checkboxTargets.forEach((checkbox) =>
      checkbox.addEventListener('change', this.toggleOne)
    );
    this.toggleOne();
  }

  disconnect() {
    if (!this.hasCheckboxAllTarget) return;

    this.checkboxAllTarget.removeEventListener('change', this.toggleAll);
    this.checkboxTargets.forEach((checkbox) =>
      checkbox.removeEventListener('change', this.toggleOne)
    );
  }

  toggleAll(e) {
    e.preventDefault();

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked;
    });
    this.toggleButtons();
  }

  toggleOne() {
    const checkboxesCount = this.checkboxTargets.length;
    const checkboxesCheckedCount = this.checked.length;

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0;
    this.checkboxAllTarget.indeterminate =
      checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount;
    this.toggleButtons();
  }

  setValue() {
    const val = this.checked.map((checkbox) => checkbox.value) || "";
    this.valuesTargets.forEach((input) => {
      input.setAttribute('value', val);
    });
  }

  displayCount() {
    const checkboxesCheckedCount = this.checked.length;
    this.checkedCountTarget.innerHTML =
      checkboxesCheckedCount > 0
        ? checkboxesCheckedCount
        : this.checkedCountTarget.dataset.default;
  }

  toggleButtons() {
    this.buttonTargets.forEach((button) => {
      button.disabled = this.checked.length === 0;
    });
    this.displayCount();
    this.setValue();
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }

  get unchecked() {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked);
  }
}
