import { Controller } from "@hotwired/stimulus";
import { toggle as toggleEl } from "el-transition";

export default class extends Controller {
  toggle(event) {
    const TARGETS = event.currentTarget.dataset.toggleTarget.split(",");

    TARGETS.forEach((target) =>
      document
        .querySelectorAll(`[data-toggle-name="${target}"]`)
        .forEach((target) => toggleEl(target))
    );
  }
}
