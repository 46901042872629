import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['main'];

  showProjectQueries(event) {
    event.preventDefault();
    this.mainTarget.classList.remove('hidden');
    document
      .getElementById('list-' + event.currentTarget.dataset.incomingCallId)
      .classList.remove('hidden');
  }

  hideAllProjectQueries() {
    this.mainTarget.classList.add('hidden');
    var lists = this.mainTarget.getElementsByClassName('project-query-list');
    for (var i = 0; i < lists.length; i++) {
      lists[i].classList.add('hidden');
    }
  }
}
