import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submit'];

  reset() {
    this.element.reset();
    this.submitTarget.disabled = true;
  }

  toggleSubmit(event) {
    this.submitTarget.disabled = event.currentTarget.value == '';
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
  }
}
