import { Controller } from 'stimulus';
import { leave, enter } from 'el-transition';

export default class extends Controller {
  static targets = ['icon', 'title', 'body'];

  connect() {
    enter(this.element);
    setTimeout(() => {
      this.close();
    }, 2000);
  }

  close() {
    leave(this.element);
  }
}
